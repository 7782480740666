import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPage = ({ data }) => (
    <Layout currentPage="bloglist">
        <SEO title="Atamagai Blog" />
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="white-text" style={{ marginTop: "0px" }}>
                        Atamagai blog
                    </h1>
                </div>
            </div>
            <div className="row align-items-stretch">
                {data.allMarkdownRemark.edges.map((post) => (
                    <div
                        className="col-12 col-md-4"
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        key={post.node.id}
                    >
                        <div className="card small hoverable">
                            <div className="card-image">
                                <Img
                                    className="z-depth-1"
                                    style={{ height: "200px" }}
                                    fluid={
                                        post.node.frontmatter.featuredImage
                                            .childImageSharp.fluid
                                    }
                                >
                                    {post.node.frontmatter.title}
                                </Img>
                                <span className="card-title">
                                    {post.node.frontmatter.title}
                                </span>
                            </div>
                            <div className="card-content">
                                <p>
                                    {post.node.frontmatter.date}{" "}
                                    {post.node.frontmatter.author} |{" "}
                                    {post.node.frontmatter.category}
                                    {/* {post.node.excerpt} */}
                                </p>
                            </div>
                            <div className="card-action align-right">
                                <a
                                    className="atab-text"
                                    href={post.node.frontmatter.path}
                                >
                                    Pročitaj više
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </Layout>
);

export const pageQuery = graphql`
    query BlogIndexQuery {
        allMarkdownRemark {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        path
                        date
                        author
                        category
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    excerpt
                }
            }
        }
    }
`;

export default BlogPage;
